<template>
  <div class="table_sty">
    <div class="froms" style="margin: 5px">
      <div>基本信息</div>
      <div class="wire"></div>

      <el-row :gutter="20">
        <el-col :span="24">
          <div style="display: flex">
            <div class="message">
              <p>风险点名称：</p>
              <p class="p1">{{ data.name }}</p>

              <div class="sign">
                <div class="sign-1" :style="{ background: data.back_color }">
                  {{ data.level_type }}
                </div>
                <div
                  class="sign-2"
                  :style="{
                    border: `1px  solid ${data.back_color}`,
                    color: data.back_color,
                  }"
                >
                  {{ data.level_color }}
                </div>
              </div>
            </div>

            <div class="message" style="padding-left: 30px">
              <p>责任部门：</p>
              <p class="p1">{{ data.department }}</p>
            </div>
          </div>
        </el-col>

        <el-col :span="8">
          <div class="message">
            <p>检查频率：</p>
            <p class="p1">{{ data.check_times }}</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="message">
            <p>风险点标签：</p>

            <el-tag
              style="margin: 0 5px 10px 0"
              v-for="(item, index) in data.tags"
              :key="index"
              >{{ item }}</el-tag
            >
          </div>
        </el-col>
      </el-row>

      <el-collapse v-model="activeNames">
        <el-collapse-item title="风险成因" name="1">
          <p>{{ data.ext_causes }}</p>
        </el-collapse-item>
        <el-collapse-item title="导致后果" name="2">
          <p>{{ data.ext_danger }}</p>
        </el-collapse-item>
        <el-collapse-item title="管控措施" name="3">
          <p>{{ data.ext_controll }}</p>
        </el-collapse-item>
        <el-collapse-item title="应急措施" name="4">
          <p>{{ data.ext_plans }}</p>
        </el-collapse-item>
        <el-collapse-item title="警示图片" name="5">
          <div class="imgs">
            <div v-for="(item, index) in ext_icons" :key="index">
              <el-image :src="item" alt="" :preview-src-list="[item]" />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="froms particulars-pop">
      <tablepop :id="id" url_type="dangerous" ref="tablepop" />
    </div>
  </div>
</template>

<script>
import { dangerousdetail } from "../../assets/request/api";
import tablepop from "../../components/controlog/dangerous_logs";
export default {
  name: "risk_particulars",
  components: { tablepop },
  data() {
    return {
      activeNames: ["1", "2", "3", "4",'5'],
      id: "",
      data: {},
      ext_icons: [],
    };
  },
  created() {
    let { id } = this.$route.query;
    this.id = id;
    dangerousdetail({ id }).then((res) => {
      this.data = res.data.data;
      let { ext_icons } = res.data.data;
      console.log(ext_icons);
      if (ext_icons) {
        this.ext_icons = ext_icons.split(",");
      }
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.message {
  display: flex;
  font-size: 15px;
  color: #28307c;
  margin-top: 10px;
  .p1 {
    color: #465269;
  }
}
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.result {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #19be6b;
  }
}
.wire {
  height: 1px;
  background: #e8eaec;
  margin: 10px -15px 0 -15px;
}
.imgs {
  display: flex;

  .el-image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.sign {
  margin-left: 30px;
  display: flex;
  div {
    width: 30px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 9px;
    color: #ffffff;
    box-sizing: border-box;
  }
}

.sign-1 {
  border-radius: 5px 0 0 5px;
}
.sign-2 {
  border-radius: 0 5px 5px 0;
}
</style>
